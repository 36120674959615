<template>
    <Link :href="usePage().props.urls.app" class="flex items-center">
        <div v-if="iconOnly">
            <img class="w-[24px] h-[24px]" :src="usePage().props.urls.media + '/ph_logo_boxed.svg'" alt="Producthero" />
        </div>

        <div v-else>
            <img class="h-12" :src="getSrc" alt="Producthero" />
        </div>
    </Link>
</template>

<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed } from 'vue';

    const props = defineProps({
        iconOnly: {type: Boolean, default: false},
        dark: {type: Boolean, default: false}
    });

    const getSrc = computed(() => {
        if (props.dark === true) {
            return usePage().props.urls.media + '/ph_logo_full_dark.svg';
        }
        return usePage().props.urls.media + '/ph_logo_full_dark.svg';
    });
</script>
